<template>
  <div class="app-container">
    <!-- 搜索栏开始 -->
    <div class="searchHeader">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="交易日期">
          <el-date-picker v-model="formInline.time" type="datetime" placeholder="选择日期时间" value-format="yyyyMMdd"></el-date-picker>
        </el-form-item>
        <el-form-item label="商户编号">
          <el-input v-model="formInline.shopId" placeholder="商户编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchData" round plain icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 搜索栏结束 -->
    <!-- 表格开始 -->
    <el-table
      border
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :data="tableDataList"
      style="width: 100%;"
      height="700"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="序号" width="80">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.index }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务商名称">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.projectIds }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户名称">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.shopIds }}</span>
        </template>
      </el-table-column>
       <el-table-column label="商户编号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.shopId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日交易总金额">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.chargeTotal }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日交易总手续费">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.chargeFeeTotal }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日交易总笔数">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.num }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易日期">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.billDay }}</span>
        </template>
      </el-table-column>
      <el-table-column label="清算时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.clearTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分润金额">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.profitMoney }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->
    <!-- 分页 -->
    <div style="text-align:right;">
      <Paging :totalSize="totalSize" :getMethods="getTableData" />
    </div>
  </div>
</template>

<script>
import Paging from "../../components/Paging/Paging.vue";
import qs from "qs";
export default {
  components: {
    Paging
  },
  data() {
    return {
      loading: true,
      formInline: {
        time: null,
        shopId: ""
      },
      tableDataList: [],
      totalSize: 0
    };
  },
  methods: {
    //获取表格数据
    getTableData(pageData) {
      this.pageDatalist = { ...pageData };
      const {time,shopId} =this.formInline;
      let fixedData={
        billDay:this.time!==null?time:'',
        shopId:shopId!==''?+shopId:''//shopId为number
      };
      let datalists = {
        pageConfig: {
          orderType: "desc", //升序、降序
          pageSize: this.pageDatalist.pageSize, //每页大小
          orderField: "id", //排序字段
          pageNum: this.pageDatalist.pageNum //第n页
        },
        baseBean: fixedData
      };
      this.$axios.post("/projectProfit/queryDayProfit", datalists).then(res => {
        this.loading = false;
        if (res.data.state == 1) {
          this.tableDataList = res.data.profitTbPage;
          const shopTbMap= res.data.shopTbMap;
          const projectTbMap= res.data.projectTbMap;
          let index =(this.pageDatalist.pageNum - 1) * this.pageDatalist.pageSize; //分页时编号计算
          this.tableDataList.map(item => {
            index++;
            item.index = index;
            item.shopIds=shopTbMap[item.shopId];
            item.projectIds=projectTbMap[item.projectId];
            if (item.clearTime !== undefined) {
              item.clearTime = this.getTime(item.clearTime);
            }
            return item;
          });
          this.totalSize = res.data.count;
        }
      });
    },
    // 查询
    searchData() {
      this.getTableData(this.pageDatalist);
    },
    // 时间转换
    getTime(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "-" +
          (this.getMonth() + 1) +
          "-" +
          +this.getDate() +
          "" +
          " " +
          this.getHours() +
          ":" +
          this.getMinutes() +
          ":" +
          this.getSeconds()
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.searchHeader {
  display: flex;
  justify-content: space-between;
}
</style>